import React from "react";
import { LOGO } from "../../utils/images";

const Logo = () => {
  return (
    <div className="topLogoPart">
      <img src={LOGO} alt="Logo" />
    </div>
  );
};

export default Logo;
