import React from "react";
import Container from "@mui/material/Container";

const TopBarComponent = () => {
  return (
    <div className="topBarMain">
      <Container>
            <p>We are currently working on the updates, site will be available soon.</p>
      </Container>
    </div>
  );
};

export default TopBarComponent;
