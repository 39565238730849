import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Policy from './Policy/Policy';        
import Terms from './terms/Terms';         
import Homepage from './homepage/Homepage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

export default App;
