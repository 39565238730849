export const LOGO = "/images/logo/logo.png";
export const FOOTERLOGO = "/images/logo/footerlogo.png";

export const RIGHTARROW = "/images/rightArrow.png";
export const SIGNUP = "/images/signup.png";
export const RECOMMEND = "/images/recommend.png";
export const EXPLORE = "/images/explore.png";
export const ACCESS = "/images/access.png";
export const WORKIMAGE = "/images/image51.png";
export const FRAME = "/images/Frame.png";
export const TESTS = "/images/tests.png";
export const TICKICON = "/images/tick.png";

export const BANNERUSERONE = "/images/banneruseroOne.png";
export const BANNERUSERTWO = "/images/bannerUserTwo.png";
export const BANNERUSERSTHREE = "/images/banneruserthree.png";
export const BANNERUSERSBG = "/images/banneruserbg.png";
export const BANNERUSERS = "/images/bannerusers.png";




export const FACEBOOK = "/images/social/facebook.png";
export const INSTAGRAM = "/images/social/instagram.png";
export const LINKEDIN = "/images/social/linkedin.png";
export const TWITTER = "/images/social/twitter.png";
