import React from "react";
import { Bounce } from "react-reveal-animation";
import Logo from "./header/logo";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  { name: "Home", hrefTo: "#home" },
  { name: "How It Works ", hrefTo: "#works" },
  { name: "Features", hrefTo: "#features" },
  { name: "Pricing", hrefTo: "#pricing" },
  { name: "Testimonial", hrefTo: "#testimonial" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: "#f1feff", boxShadow: "none" }}
      id="home"
    >
      <Bounce>
        <Container>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo />
            </Typography>

            <Box
              className="toggleBtn"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon
                  sx={{
                    flexGrow: 1,
                    color: "#000",
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography
                      component="a"
                      href={page.hrefTo}
                      textAlign="center"
                      sx={{ color: "#0f6599", textDecoration: "none" }}
                    >
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                color: "#000",
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {pages.map((page) => (
                <Button
                  component="a"
                  href={`${page.hrefTo}`}
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 3,
                    color: page === "Home" ? "#0f6599" : "#707070",
                    display: "block",
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                className="BtnsLogin"
                sx={{ color: "#707070", marginRight: 2 }}
              >
                Log In
              </Button>
              <Button className="BtnsSIgnUp" variant="contained">
                Sign Up
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </Bounce>
    </AppBar>
  );
}
export default ResponsiveAppBar;
