import React from "react";
import { Bounce} from 'react-reveal-animation';
import Container from '@mui/material/Container';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {
  FACEBOOK,
  FOOTERLOGO,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
} from "../utils/images";
import { NAVBAR_MENU } from "../utils/navbarContent";
import { Button, Link } from "@mui/material";

const FooterComponent = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer text-black">
      <Bounce up>
      <Container>
        <div className="footer-upper">
          <div className="footer-upper-inner">
            <div className="footer-logo">
              <img src={FOOTERLOGO} alt="footer logo" />
            </div>
            <div className="footer-pera-first">
              With <span>Educationist.ai</span>, get personalized career guidance to make
              informed decisions about your future.
            </div>
          </div>
          <div className="footer-upper-inner">
            <h4>Quick Links</h4>
            <ul>
              <li>Home</li>
              <li>About</li>
              <li>Features</li>
              <li>Content</li>
              <li>Blog</li>
            </ul>
          </div>
          <div className="footer-upper-inner">
            <h4>Contact Information</h4>
            <p><MailIcon /> <a href="#">hello@educationist.ai</a></p>
            <p><PhoneIcon /> <a href="#">+91-8985333790</a></p>
            <p><LocationOnIcon /> LAS EDVENTURES ENTERPRISE, AT 18-13-8/CT/65/A,
              CYRSTAL TOWN COLONY, NEAR PROGRESS SCHOOL, BANDLAGUDA, HYDERABAD,
              TELANGANA, INDIA - 500005.</p>
          </div>
          <div className="footer-upper-inner">
            <h4>Social Media</h4>
            <div className="ftr-social-link">
              <img src={FACEBOOK} alt="facebook" />
              <img src={LINKEDIN} alt="linkedin" />
              <img src={TWITTER} alt="twitter" />
              <img src={INSTAGRAM} alt="instagram" />
            </div>
            <h4>Subscribe</h4>
            <div className="subscribe-main">
              <input type="text" placeholder="Your Email Address" />
              <button type="button" className="ftr-mailBtn"><ArrowForwardIcon /></button>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>&copy;{currentYear} LAS EDVENTURES ENTERPRISE . All rights reserved.</p>
          <div className="footer-list flex gap-4">
            <Link href="/policy">
              <Button className="text-blue-500 hover:underline ">Privacy Policy</Button>
            </Link>
            <Link href="/terms">
              <Button className="text-blue-500 hover:underline">Terms of Service</Button>
            </Link>
          </div>
        </div>
      </Container>
      </Bounce>
    </footer>
  );
};

export default FooterComponent;
