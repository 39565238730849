
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { LOGO } from '../utils/images';


const Policy = () => {
  return (
    <div className='bg-gradient-to-tl from-[#BCD4E3] via-white to-[#BCD4E3] min-h-screen pt-3 pb-3'>
      {/* <HeaderComponent /> */}

      <Container
        maxWidth="lg"
        sx={{ backgroundColor: 'white', padding: '2rem', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Link href="/">
          <img src={LOGO} alt="Logo" />
        </Link>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' , textAlign:'center', }} className='gradientText'>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Educationist.ai! At LAS Edventures Enterprise - Educationist.ai, we respect your privacy and are committed to protecting the personal information of our users. This Privacy Policy outlines how we collect, use, and safeguard your data when you access and use our services. By using our platform, you agree to the terms outlined in this policy.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          1. Data We Collect
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Personal Information:</strong> Name, email address, phone number, date of birth, and class/grade during registration.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Academic Information:</strong> Educational background, favorite subjects, interests, hobbies, and future plans, which help us generate tailored career recommendations.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Responses to Screening Questions:</strong> Answers provided during initial and extended screening, used to assess your career preferences and skills.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Payment Information:</strong> When purchasing premium features, we may collect payment details via secure third-party payment gateways.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          2. How We Use Your Data
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Personalized Career Guidance:</strong> To provide you with accurate career recommendations based on the responses you provide.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Platform Improvement:</strong> To enhance the platform experience by analyzing user behavior and responses.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Communication:</strong> To send you updates, notifications, or responses based on your interactions with our platform.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Compliance:</strong> To ensure compliance with legal and regulatory obligations.
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" paragraph>
          We do not use your personal data for advertising, sharing with third parties, or any activity outside of providing career coaching and recommendations.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          3. Protection of Student Privacy
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Data Security:</strong> We have implemented robust technical and organizational measures to protect your data from unauthorized access, loss, or misuse. This includes encryption, secure servers, and regular security audits.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Limited Data Usage:</strong> We only use your personal data to guide you in making informed career decisions. No data will be used for purposes beyond this without your explicit consent.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Data Minimization:</strong> We only collect the necessary data required to provide personalized career recommendations and no more.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          4. How Our AI System Uses Your Data
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Data Processing:</strong> The AI analyzes the answers you provide to generate recommendations; however, your personal data is anonymized before being processed.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>No Exposure to Privacy:</strong> The AI system is designed to ensure that personal identifiable information (PII) is not exposed or shared outside the platform. Your personal data will never be sold or shared with third parties for marketing or other purposes.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>GDPR Compliance:</strong> Our AI adheres to GDPR standards, ensuring that personal data is processed lawfully, transparently, and with respect to your privacy rights.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          5. Your Rights under Indian Law
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Right to Access:</strong> You may request to know what personal data we hold about you.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Right to Correction:</strong> You may request the correction of inaccurate or outdated information.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Right to Erasure:</strong> You may request the deletion of your personal data when it is no longer required for the purposes stated.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Right to Data Portability:</strong> Upon request, we can provide your data in a structured format.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Right to Withdraw Consent:</strong> You may withdraw your consent for processing your data at any time.
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" paragraph>
          To exercise any of these rights, you can contact us at <Link href="mailto:support@educationist.ai"><Button style={{ color: '#0f6599', textDecoration: 'none' }}>support@educationist.ai</Button></Link>. We will respond within the time period prescribed under Indian law.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          6. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We will retain your data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, including providing services and complying with legal obligations. You can request that we delete your data at any time.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          7. Data Sharing
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Service Providers:</strong> We may use third-party services (such as payment processors) that require access to your personal information to function. These service providers are bound by data protection agreements and are prohibited from using your data for any purpose other than providing services on our behalf.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Legal Obligations:</strong> We may share your data if required by law, such as responding to court orders or government requests.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          8. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar technologies to enhance your experience on our platform. You can manage your cookie preferences through your browser settings. By using our site, you consent to our use of cookies as outlined in our Cookie Policy.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          9. Data Security
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              <strong>Encryption:</strong> All personal data transmitted to or from our platform is encrypted.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Access Control:</strong> Only authorized personnel have access to user data, and they are required to follow strict confidentiality protocols.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              <strong>Regular Audits:</strong> We regularly review and improve our security practices to ensure compliance with the latest standards.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          10. Changes to this Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes via email or by posting a notice on our platform.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about our Privacy Policy or how we handle your personal data, please contact us at:
        </Typography>
        <Typography variant="body1">
          Email: <Link href="mailto:support@educationist.ai">
            <Button style={{ color: '#0f6599', textTransform: 'none' }}>hello@educationist.ai</Button>
          </Link>
        </Typography>
        <Typography variant="body1">
          Address: LAS Edventures 18-13-8/CT/65/A, Crystal town colony, near progress school, Bandlaguda, 500005
        </Typography>

        <Typography variant="body1" paragraph sx={{ marginTop: '2rem' }}>
          By continuing to use Educationist.ai, you acknowledge that you have read, understood, and agree to this Privacy Policy. Your privacy is important to us, and we are committed to protecting it.
        </Typography>
      </Container>
    </div>
  );
}

export default Policy;
