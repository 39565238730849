import React from 'react'
import TopBarComponent from "../components/topbarComponent";
import HeaderComponent from "../components/headerComponent";
import HighlightComponent from "../components/highlightComponent";
import WorkComponent from "../components/workComponent";
import TestimonialComponent from "../components/testimonialComponent";
import PricingComponent from "../components/pricingComponent";
import TestComponent from "../components/testComponent";
import FooterComponent from "../components/footerComponent";

function Homepage() {
  return (
    <div>
      <div>
      <TopBarComponent />
      <HeaderComponent />
      <HighlightComponent />
      <WorkComponent />
      {/* <TestimonialComponent /> */}
      {/* <PricingComponent /> */}
      {/* <TestComponent /> */}
      <FooterComponent />
    </div>
    </div>
  )
}

export default Homepage;
