
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
// import Logo from "../../components/header/logo";
import { Link } from 'react-router-dom';
import { LOGO } from '../utils/images';

function Terms() {
  return (
    <div className='bg-gradient-to-tl from-[#BCD4E3] via-white to-[#BCD4E3] min-h-screen pt-3 pb-3'>
      {/* <HeaderComponent /> */}
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: 'white', padding: '2rem', paddingTop: '10px',borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Link href="/">
          <img src={LOGO} alt="Logo" />
        </Link>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold',textAlign:'center' }} className="gradientText">
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Educationist.ai! These Terms and Conditions govern your use of our services. By accessing or using our platform, you agree to comply with and be bound by these terms. If you do not agree with these terms, please do not use our platform.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using Educationist.ai, you agree to be bound by these Terms and Conditions and our Privacy Policy. We may update these terms from time to time, and your continued use of the platform will constitute acceptance of any changes.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          2. Use of the Platform
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
          <Box component="li">
            <Typography variant="body1">
              You agree to use the platform for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else&apos;s use and enjoyment of the platform.
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body1">
              You must not use the platform to transmit or distribute any harmful or illegal content or to engage in any activity that could damage the platform or its users.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          3. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content and materials available on Educationist.ai, including but not limited to text, graphics, logos, and software, are the property of LAS Edventures Enterprise - Educationist.ai or its licensors and are protected by intellectual property laws.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          4. Disclaimers and Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          The platform is provided &quot;as is&quot; without warranties of any kind. We do not guarantee the accuracy, completeness, or reliability of the content or services provided. To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, or consequential damages arising from or related to your use of the platform.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          5. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to terminate or suspend your access to the platform at our sole discretion, without notice, for any reason, including but not limited to violation of these Terms and Conditions.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          6. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of India.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: '1.5rem', color: 'black' }}>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about these Terms and Conditions, please contact us at:
        </Typography>
        <Typography variant="body1">
          Email: <Link href="mailto:hello@educationist.ai">
            <Button style={{ color: '#0f6599', textTransform:'none' }}>hello@educationist.ai</Button>
          </Link>
        </Typography>
        <Typography variant="body1">
        Address: LAS Edventures 18-13-8/CT/65/A, Crystal town colony, near progress school, Bandlaguda, 500005
        </Typography>

        <Typography variant="body1" paragraph sx={{ marginTop: '2rem' }}>
          By continuing to use Educationist.ai, you acknowledge that you have read, understood, and agree to these Terms and Conditions. Your use of our platform signifies your acceptance of these terms.
        </Typography>
      </Container>
    </div>
  );
}

export default Terms;
