import React from "react";
import { WORKIMAGE } from "../../utils/images";

const LeftWork = () => {
  return (
    <div className="work-image-left">
      <img src={WORKIMAGE} alt="" />
    </div>
  );
};

export default LeftWork;
